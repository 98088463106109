<template>
  <div class="select_product">
    <van-nav-bar
        title="选择产品"
        left-text="返回"
        left-arrow
        fixed
        @click-left="onClickLeft"
    />
    <search
        class="select_product_search"
        @addWorkOrder="addProduct"
        :keyword="productName"
        @headleSearch="searchProduct"
    />
    <scroll class="select_product_scroll" :onLoadMore="onLoadMore">
      <div
          class="all_product"
          v-for="item in selectProductList"
          :key="item.id"
          @click.stop="toCreateWork(item)"
      >
        <div class="all_product_l">
          <div class="all_product_name">
            {{ item.productName }} | {{ item.productCode }}
          </div>
          <div class="all_product_specification">
            {{ item.productRank }}
          </div>
        </div>
        <div class="icon icon-xiugai" @click.stop="toAddproduct(item.id)"></div>
      </div>
    </scroll>
  </div>
</template>
<script>
import search from "../../components/search/index";
import scroll from "../../components/scroll/index";
import {ChangePageTitle} from "../../common/js/upTitle";
import {getDepartmentCodeList} from "@/api/work.js";
import {searchProduct} from '@/api/product.js'
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      productName: "",
      selectProductList: []
    };
  },
  created() {
    ChangePageTitle("选择产品");
    this.getDepartmentCodeList(this["departmentCode"]);
  },
  methods: {
    onClickLeft() {
      this.$router.replace({path: "/createWork"});
    },
    //搜索产品
    async searchProduct(v) {
      try {
        const params = {
          departmentCode: this["departmentCode"],
          productName: v,
        };
        const res = await searchProduct(params);
        this.selectProductList = res.records
      } catch (err) {
        console.log(err)
      }
    },
    addProduct() {
      this.$router.push("/addProduct");
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    async getDepartmentCodeList(departmentCode) {
      const res = await getDepartmentCodeList(departmentCode);
      this.selectProductList = res;
    },
    toCreateWork(i) {
      this.$router.push({
        name: "/createWork",
      });
      var params = {
        productCode: i.productCode,
        productName: i.productName,
        productUnit: i.productUnit,
        productRank: i.productRank
      }
      window.localStorage.setItem('selectProductList', JSON.stringify(params))
    },
    toAddproduct(id) {
      window.localStorage.setItem('prodcutId', id)
      this.$router.push({
        name: '/addProduct'
      })
    },
  },
  components: {
    search,
    scroll,
  },
};
</script>
<style lang="less" scoped>
.select_product {
  width: 100vw;
  height: 100vh;

  .select_product_search {
    position: fixed;
    left: 0;
    top: 85px;
  }

  .select_product_scroll {
    padding-top: 160px;
  }

  .all_product {
    width: 95%;
    margin: auto;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 30px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;

    .all_product_name {
      color: black;
      margin-bottom: 15px;
    }

    .icon {
      font-size: 40px;
      color: blue;
    }
  }
}
</style>
