import request from "@/utils/request";
//搜索产品信息
export function searchProduct(data){
    return request({
        url:'/ewo/ewoProduct/getPageList',
        method:'post',
        data
    })
}
export function toUpdate(data){
    return request({
        url:'ewo/ewoProduct/update',
        method:'post',
        data
    })
}
export function addProduct(data){
    return request({
        url:'ewo/ewoProduct/add',
        method:'post',
        data
    })
}
export function getEwoUnitList(departmentCode){
    return request({
        url:`ewo/ewoUnit/getEwoUnitListByDepartmentCode/${departmentCode}`,
        method:'get'
    })
}
export function getProduct(id){
    return request({
        url:`ewo/ewoProduct/info/${id}`,
        method:'get'
    })
}