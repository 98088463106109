<template>
    <div class="search">
        <van-search
            v-model="currentSearch"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
            @clear ="onclear"
            background="#f5f7f9"
        >
            <template #action>
                <div @click="addWorkOrder" class="icon icon-jjia-"></div>
            </template>
        </van-search>
    </div>
</template>
<script>
export default {
    props:{
        keywords:{
            type:String,
            required:false
        }
    },
    data(){
        return{
            currentSearch:this.keywords
        }
    },
    methods: {
        onSearch() {
            this.$emit('headleSearch',this.currentSearch)
        },
        addWorkOrder(){
            this.$emit('addWorkOrder')
        },
        onclear(){
            this.$emit('headleclear')
        }
    },
};
</script>
<style lang="less" scoped>
.search{
    width: 100vw;
    height: 100px;
    /deep/ .van-search__content {
        background-color: #fff;
        border-radius: 10px;
    }
    /deep/ .van-search__action{
        .icon{
            color: blue;
            font-size: 40px;
        }
    }
}
</style>
